import { ActivatedRoute, Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { FuseAlertType } from '../components/alert/alert.types';
import { fuseAnimations } from '../components/animations';
import { Platform } from '@ionic/angular';
import { passwordConfirming } from '../validators/passwordConfriming.validator';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ForgetPasswordComponent implements OnInit {
  form: FormGroup;
  token;
  tokenDados;
  tokenInvalido = false;
  success = false;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: '',
  };
  showAlert: boolean = false;
  is_app = false;
  constructor(
    private api: ApiService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,

    public platform: Platform
  ) {
    this.createForm();
  }

  async ngOnInit() {
    if (
      (this.platform.is('mobile') ||
        this.platform.is('tablet') ||
        this.platform.is('ios') ||
        this.platform.is('android')) &&
      !this.platform.is('mobileweb')
    ) {
      this.is_app = true;
    } else {
      this.is_app = false;
    }
    let hoje = new Date();
    await this.route.params.subscribe((params) => {
      if (params['token']) {
        this.token = params['token'];
      }
    });
    await this.getToken();
    if (this.tokenDados) {
      if (
        new Date(this.tokenDados.validade).toISOString() <
        new Date(hoje).toISOString()
      ) {
        this.tokenInvalido = true;
        this.atualizarToken();
      } else {
        if (!this.tokenDados.ativo) {
          this.tokenInvalido = true;
        } else {
          this.tokenInvalido = false;
        }
      }
    } else {
      this.tokenInvalido = true;
    }
    if (this.tokenInvalido) {
      this.showAlert = true;
      this.alert = {
        type: 'error',
        message: 'Link Inválido, Este link não está mais válido',
      };
    }
  }

  async getToken() {
    await this.api
      .get('resetSenha/' + this.token)
      .then((response: any) => {
        this.tokenDados = response;
      })
      .finally(() => {
        this.cdr.detectChanges();
      });
  }

  async atualizarToken() {
    this.tokenDados.ativo = false;
    await this.api
      .put('resetSenha/' + this.tokenDados.reset_senha_id, this.tokenDados)
      .then((response: any) => { })
      .finally(() => {
        this.cdr.detectChanges();
      });
  }
  createForm() {
    this.form = this._formBuilder.group({

      senha_nova: ['', Validators.compose([Validators.required])],
      confirme_senha: ['', Validators.compose([Validators.required])],

    }, { validator: passwordConfirming });
  }

  onSubmit() {
    this.showAlert = false;

    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    let usuario = {};
    Object.keys(controls).forEach(
      (controlName) => (usuario[controlName] = controls[controlName].value)
    );

    this.api
      .put('resetSenha/nova/senha/' + this.token, usuario)
      .then((response: any) => {
        this.showAlert = true;
        this.alert = {
          type: 'success',
          message:
            'Senha Alterada! Você pode clicar no botão abaixo para ser redirecionado para a página de entrar.',
        };
        this.success = true;
      })
      .catch((erro) => {
        this.showAlert = true;
        this.alert = {
          type: 'error',
          message: 'Ocorreu um erro!',
        };
      });
  }
}
