import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { FuseAlertType } from '../components/alert/alert.types';
import { fuseAnimations } from '../components/animations';
import { Platform } from '@ionic/angular';
import { passwordConfirme } from '../validators/passwordConfirme.validator';

interface FiltroRequestUsuario {
  iddesenvolvedor: string;
}

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AlterarSenhaComponent implements OnInit {
  alterarSenhaForm: FormGroup;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };
  filtro_request_usuario: FiltroRequestUsuario = {
    iddesenvolvedor: localStorage.getItem('idDesenvolvedor'),
  };
  showAlert: boolean = false;
  is_app = false;
  confirma_senha;
  usuarios: any[] = [];
  constructor(
    private _activatedRoute: ActivatedRoute,
    private api: ApiService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private cdr: ChangeDetectorRef,
    public platform: Platform) {
    this.alterarSenhaForm = this._formBuilder.group({
      usuario: ['', Validators.required],
      senha: ['', Validators.required],
      confirme_senha: ['', Validators.required],
      usuario_id: [''],
    },{ validator: passwordConfirme });
  }

  ngOnInit() {
    if ((this.platform.is('mobile') || this.platform.is('tablet') || this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb')) {
      this.is_app = true
    } else {
      this.is_app = false
    }
    this.getUsuarios();
  }

  ionViewWillEnter() {
    this.getUsuarios();
  }

  alterarSenha(): void {
    if (this.alterarSenhaForm.invalid) {
      return;
    }

    this.alterarSenhaForm.disable();

    this.showAlert = false;
    this.alterarSenhaForm.controls.usuario_id.setValue(localStorage.getItem('id'));
    this.api.alterar(this.alterarSenhaForm.value)
      .then(
        (response: any) => {
          if (response.retorno == 'Senha alterada!') {
            this.alterarSenhaForm.reset();
            this.alterarSenhaForm.enable();
            this.confirma_senha = '';
            this.getUsuarios();
            this.alert = {
              type: 'success',
              message: 'Senha alterada com sucesso'
            };
            this.showAlert = true;
          } else {
            this.alterarSenhaForm.enable();
            this.alterarSenhaForm.reset();
            this.confirma_senha = '';
            this.getUsuarios();
            this.alert = {
              type: 'error',
              message: 'Houve um erro ao tentar alterar a senha'
            };
            this.showAlert = true;
            this.cdr.detectChanges();
          }
        }
      ).catch(e => {

      });
    this.cdr.detectChanges();
  }

  getUsuarios() {
    this.filtro_request_usuario.iddesenvolvedor = localStorage.getItem('idDesenvolvedor');
    this.api.get('usuario/'+ localStorage.getItem('id')).then(
      async (response: any) => {
        if (response) {
          // this.usuarios = [];
          // this.usuarios = response.usuarios;
          // let usuario_nome = response.usuario;
          this.alterarSenhaForm.controls.usuario.setValue(response.usuario);
        }
      }
    ).finally(() => {
      setTimeout(() => {
        this.cdr.detectChanges();
      }, 200);
    });
  }
}
