import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ApexChart, ApexNonAxisChartSeries, ApexOptions, ApexResponsive, ChartComponent } from 'ng-apexcharts';
import { ApiService } from '../service/api.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ViewWillEnter } from '@ionic/angular';
import { MatDialog } from '@angular/material/dialog';
import { EmailUsuarioDialogComponent } from '../components/email-usuario-dialog/email-usuario-dialog.component';
import { Overlay } from '@angular/cdk/overlay';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
interface Empresa {
  empresa: string,
  valorTotal: string,
  numeroVendas: string,
  ticketMedio: string,
  numeroItens: string,
}

interface FiltroRequest {
  usuario_id: string;
  dataInicio: string;
  dataFim: string;
}
@Component({
  selector: 'app-geral',
  templateUrl: './geral.component.html',
  styleUrls: ['./geral.component.scss'],
})
export class GeralComponent implements OnInit, ViewWillEnter {
  apexChartOptions: ApexOptions = {
    chart: {
      height: 500,
      width: "100%",
      type: 'pie',
      animations: {
        speed: 400,
      },
    },
    labels: [],
    series: [],
    legend: {
      // position: 'bottom',
      width: 400,
      horizontalAlign: 'left',
      fontSize: '14px',
      offsetX: 0,
      itemMargin: {
        horizontal: 0,
        vertical: 0
      },
      formatter: (seriesName, opts) => {
        const round = (num, places) => {
          return +(parseFloat(num).toFixed(places));
        }
        // const class3w = (index) => {
        //   if (index % 2) {
        //     return '<span style="background-color:#dedede; padding: 10px 0; border-radius:2px; line-height: 2.5">';
        //   } else {
        //     return '<span >';
        //   }
        // }
        // return class3w(opts.seriesIndex) + `

        return `<span >${opts.w.globals.series[opts.seriesIndex].toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }) + " | " + round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) + '%' + " | " + seriesName} </span>`

      },
    },
    title: {
      text: undefined,
      align: 'left',
      margin: 20,
      offsetX: 50,
      offsetY: 0,
      style: {
        fontSize: '25px',
        fontWeight: 'bold',
        fontFamily: 'inherit',
        color: '#303030'
      },
    },
    subtitle: {
      text: undefined,
      align: 'left',
      margin: 20,
      offsetX: 50,
      offsetY: 370,
      style: {
        fontSize: '18px',
        fontWeight: 'normal',
        fontFamily: 'inherit',
        color: '#303030'
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.8,
        expandOnClick: true,
        offsetX: -100,
        offsetY: -70,
        dataLabels: {
          offset: -10
        },
      }
    },
    dataLabels: {
      style: {
        fontSize: '18px',
        fontFamily: 'Roboto, Arial, sans-serif',
        fontWeight: 'bold',
        colors: ['#ffffff']
      }
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          plotOptions: {
            pie: {
              offsetX: 0,
            }
          },
          legend: {
            offsetX: 0,
          }
        }
      },
      {
        breakpoint: 1000,
        options: {
          chart: {
            height: 300,
            width: '100%',
          },
        }
      },
      {
        breakpoint: 800,
        options: {
          plotOptions: {
            pie: {
              customScale: 1,
              offsetX: 0,
              offsetY: 0,
            }
          },
          subtitle: {
            margin: 20,
            offsetX: 0,
            offsetY: 40,
          },
          title: {
            margin: 20,
            offsetX: 0,
            offsetY: 0,
          },

          chart: {
            height: 10000,
            width: "100%",
          },
          legend: {
            width: '100%',
            position: "bottom",
            fontSize: '14px',
            offsetX: -20,
            offsetY: 0,
          },
          dataLabels: {
            style: {
              fontSize: '14px',
            }
          }
        }
      },
    ],
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: 'dark',
      custom: ({ seriesIndex, w }): string => {
        return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
              <div class="w-3 h-3 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
              <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
              <div class="ml-2 text-md font-bold leading-none">${w.globals.series[seriesIndex].toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}</div>
            </div>`
      }
    }
  };
  empresa: Empresa[] = [];
  date = new Date();
  init_dash = false;



  cht_vendas_title = { ...this.apexChartOptions.title };
  cht_vendas_subtitle = { ... this.apexChartOptions.subtitle };
  cht_vendas_series = { ... this.apexChartOptions.series };
  cht_vendas_labels = { ... this.apexChartOptions.labels };
  cht_vendas_legend = { ... this.apexChartOptions.legend };
  cht_vendas_tooltip = { ... this.apexChartOptions.tooltip };
  cht_vendas_colors = { ... this.apexChartOptions.colors };

  cht_total_title = { ...this.apexChartOptions.title };
  cht_total_subtitle = { ... this.apexChartOptions.subtitle };
  cht_total_series = { ... this.apexChartOptions.series };
  cht_total_labels = { ... this.apexChartOptions.labels };
  cht_total_legend = { ... this.apexChartOptions.legend };
  cht_total_tooltip = { ... this.apexChartOptions.tooltip };

  cht_ticket_title = { ...this.apexChartOptions.title };
  cht_ticket_subtitle = { ... this.apexChartOptions.subtitle };
  cht_ticket_series = { ... this.apexChartOptions.series };
  cht_ticket_labels = { ... this.apexChartOptions.labels };
  cht_ticket_legend = { ... this.apexChartOptions.legend };
  cht_ticket_tooltip = { ... this.apexChartOptions.tooltip };
  cht_ticket_colors = { ... this.apexChartOptions.colors };

  cht_numero_itens_title = { ...this.apexChartOptions.title };
  cht_numero_itens_subtitle = { ... this.apexChartOptions.subtitle };
  cht_numero_itens_series = { ... this.apexChartOptions.series };
  cht_numero_itens_labels = { ... this.apexChartOptions.labels };
  cht_numero_itens_legend = { ... this.apexChartOptions.legend };
  cht_numero_itens_tooltip = { ... this.apexChartOptions.tooltip };
  cht_numero_itens_colors = { ... this.apexChartOptions.colors };



  filtro_request: FiltroRequest = {
    usuario_id: localStorage.getItem('id'),
    dataInicio: this.date.toISOString().substring(0, 10),
    dataFim: this.date.toISOString().substring(0, 10),
  };
  filtro = {
    dataInicio: this.date,
    dataFim: this.date,
    mesAtual: new Date().getMonth(),
    semanaAtual: 0,
    dia: 0,
    selecionado: 'DIA'
  }
  mesAtual = new Date().getMonth();
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private overlay: Overlay,
  ) {
  }

  ngOnInit() {
  }
  ionViewWillEnter() {
    this.getContador();
    this.verificarEmailUsuario();
  }
  getContador(event?) {
    this.init_dash = false;
    this.cdr.detectChanges();
    this.filtro_request.usuario_id = localStorage.getItem('id') ? localStorage.getItem('id') : 'null';
    this.filtro_request.dataFim = this.datePipe.transform(this.filtro.dataFim, "yyyy-MM-dd");
    this.filtro_request.dataInicio = this.datePipe.transform(this.filtro.dataInicio, "yyyy-MM-dd");
    this.api.post('api/contador', this.filtro_request).then(
      async (response: any) => {
        if (response) {
          this.empresa = [];
          for (let a = 0; a < response.empresa.length; a++) {
            const element = response.empresa[a];
            this.empresa.push(element)
            // ==============labels=============
          }
          // this.cht_labels = await this.empresa.map((obj) => {
          //   return obj.empresa;
          // });

          // ================valor total==============
          this.cht_total_title.text = 'Valor Total';
          this.cht_total_subtitle.text = 'Valor Total = ' + Number(response.valorTotal).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
          let cht_total = this.empresa.sort((a: any, b: any) => Number(b.valorTotal) - Number(a.valorTotal));
          this.cht_total_series = await cht_total.map((obj) => {
            return Number(obj.valorTotal);
          });
          this.cht_total_labels = await cht_total.map((obj) => {
            return obj.empresa;
          });

          // ================vendas==============
          this.cht_vendas_title.text = 'Número de vendas';
          this.cht_vendas_subtitle.text = 'Total Números de Vendas = ' + response.numeroVendas.toString();
          let cht_vendas = this.empresa.sort((a: any, b: any) => Number(b.numeroVendas) - Number(a.numeroVendas));
          this.cht_vendas_series = await cht_vendas.map((obj) => {
            return Number(obj.numeroVendas);
          });
          this.cht_vendas_labels = await cht_vendas.map((obj) => {
            return obj.empresa;
          });

          this.cht_vendas_tooltip.custom = ({ seriesIndex, w }): string => {
            return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                      <div class="w-3 h-3 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
                      <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                      <div class="ml-2 text-md font-bold leading-none">${w.globals.series[seriesIndex]}</div>
                    </div>`
          };
          this.cht_vendas_legend.formatter = (seriesName, opts) => {
            const round = (num, places) => {
              return +(parseFloat(num).toFixed(places));
            }
            // const class3w = (index) => {
            //   // if (index % 2) {
            //   //   return '<span style="background-color:#dedede; padding: 10px 0; border-radius:2px; line-height: 2">';
            //   // } else {
            //   //   return '<span >';
            //   // }
            // }
            return `<span >
              ${opts.w.globals.series[opts.seriesIndex] + " | " + round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) + '%' + " | " + seriesName}
              </span>`
          };
          this.cht_vendas_colors = ["#ffbc58", "#01cccd", "#6610f2", "#dc3545", "#f11fea", "#187e0c", "#fee440", "#00bbf9", "#00f5d4", "#f15bb5", "#15616d", "#78290f", "#ff99c8", "#92e6a7"];

          // ================ticket==============
          this.cht_ticket_title.text = 'Ticket Médio';
          this.cht_ticket_subtitle.text = 'Total Ticket Médio = ' + Number(response.ticketMedio).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
          let cht_ticket = this.empresa.sort((a: any, b: any) => Number(b.ticketMedio) - Number(a.ticketMedio));
          this.cht_ticket_series = await cht_ticket.map((obj) => {
            return Number(obj.ticketMedio);
          });
          this.cht_ticket_labels = await cht_ticket.map((obj) => {
            return obj.empresa;
          });
          this.cht_ticket_colors = ["#e60049", "#0bb4ff", "#50e991", "#9b19f5", "#b3d4ff", "#ffa300", "#dc0ab4", "#00bfa0", "#e6d800", "#b30000", "#7c1158", "#4421af", "#1a53ff"];

          // ================Itens==============
          this.cht_numero_itens_title.text = 'Numero Itens Vendidos';
          this.cht_numero_itens_subtitle.text = 'Total Itens Vendidos = ' + response.numeroItens.toString();
          let cht_numero_itens = this.empresa.sort((a: any, b: any) => Number(b.numeroItens) - Number(a.numeroItens));
          this.cht_numero_itens_series = await cht_numero_itens.map((obj) => {
            return Number(obj.numeroItens);
          });
          this.cht_numero_itens_labels = await cht_numero_itens.map((obj) => {
            return obj.empresa;
          });

          this.cht_numero_itens_tooltip.custom = ({ seriesIndex, w }): string => {
            return `<div class="flex items-center h-8 min-h-8 max-h-8 px-3">
                      <div class="w-3 h-3 rounded-full" style="background-color: ${w.globals.colors[seriesIndex]};"></div>
                      <div class="ml-2 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
                      <div class="ml-2 text-md font-bold leading-none">${w.globals.series[seriesIndex]}</div>
                    </div>`
          };
          this.cht_numero_itens_legend.formatter = (seriesName, opts) => {
            const round = (num, places) => {
              return +(parseFloat(num).toFixed(places));
            }
            // const class3w = (index) => {
            //   // if (index % 2) {
            //   //   return '<span style="background-color:#dedede; padding: 10px 0; border-radius:2px; line-height: 2">';
            //   // } else {
            //   //   return '<span >';
            //   // }
            // }
            return `<span >
              ${opts.w.globals.series[opts.seriesIndex] + " | " + round(opts.w.globals.seriesPercent[opts.seriesIndex], 2) + '%' + " | " + seriesName}
              </span>`
          };
          this.cht_numero_itens_colors = ["#ffbc58", "#01cccd", "#6610f2", "#dc3545", "#f11fea", "#187e0c", "#fee440", "#00bbf9", "#00f5d4", "#f15bb5", "#15616d", "#78290f", "#ff99c8", "#92e6a7"];

        }
      }
    ).finally(() => {
      setTimeout(() => {
        this.init_dash = true;
        this.cdr.detectChanges();
        event?.target.complete();
      }, 200);
    });
  }

  proximo() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual++;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual++;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia++;
        this.addDia();
        break;
    }
  }

  anterior() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual--;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual--;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia--;
        this.addDia();
        break;
    }
  }

  addSemana() {
    this.filtro.selecionado = 'SEMANA';
    let inicio = moment().add(this.filtro.semanaAtual, 'weeks').day(0); // domingo desta semana
    let fim = moment().add(this.filtro.semanaAtual, 'weeks').day(6); // sábado desta semana
    this.filtro.dataInicio = new Date(inicio.toString());
    this.filtro.dataFim = new Date(fim.toString());
  }
  addMes() {
    this.filtro.selecionado = 'MES';
    let d = new Date();
    let anoC = d.getFullYear();
    let mesC = this.filtro.mesAtual;
    this.filtro.dataInicio = new Date(anoC, mesC, 1);
    this.filtro.dataFim = new Date(anoC, mesC + 1, 0);
  }
  addDia() {
    this.filtro.selecionado = 'DIA';
    let date = new Date();
    let emissaoDe = new Date();
    let emissaoAte = new Date();
    this.filtro.dataInicio = new Date(emissaoDe.setDate(date.getDate() + this.filtro.dia));
    this.filtro.dataFim = new Date(emissaoAte.setDate(date.getDate() + this.filtro.dia));
  }

  verificarEmailUsuario() {
    if (localStorage.getItem('uscsw')) {
      const usuario = JSON.parse(localStorage.getItem('uscsw'));
      if (!usuario.email || usuario.email == '' || usuario.email == null || usuario.email == 'null' || usuario.email == undefined || usuario.email == 'undefined') {
        this.getUsuario()
      }
    } else {
      this.getUsuario();
    }
  }

  getUsuario() {
    this.api.get('usuario/' + localStorage.getItem('id')).then((e: any) => {
      localStorage.setItem('uscsw', JSON.stringify(e));
      if (!e.email) {
        this.openDialogExcluirUsuario(e);
      }
    })
  }
  openDialogExcluirUsuario(usuario): void {

    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const { ...data } = {
      // icon: 'help_outline',
      icon: 'warning',
      icon_color: '#a70808',
      title: '<span class="text-red-800">Atenção</span>',
      sub_title: 'Você deve adicionar um email válido ao seu usuário.',
      message: 'Com o esse email você poderá resetar sua senha com mais segurança. <br><br> Esse email não poderá ser usado em outro usuário do Prioriza B.I.. <br><br> Todos usuários devem adicionar um email a partir de 20/06/2022.',
      actions: {
        confirm: {
          label: 'ADICIONAR EMAIL',
          color: '#a70808',
        },
        // "cancel": {
        //   "label": "Cancelar"
        // }
      },
      dismissible: true,
    };
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '500px',
      data: data,
      panelClass: 'succes-modal',
      scrollStrategy,
    });
    dialogRef.afterClosed().subscribe((result) => {
      const dialogRef = this.dialog.open(EmailUsuarioDialogComponent, {
        width: '500px',
        data: usuario,
        disableClose: true,
        panelClass: 'succes-modal',
        scrollStrategy,
      });
    });
  }
}
