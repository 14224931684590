import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ApexChart, ApexNonAxisChartSeries, ApexOptions, ApexResponsive, ChartComponent } from 'ng-apexcharts';
import { ApiService } from '../service/api.service';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

interface FiltroRequest {
  usuario_id: string;
}
@Component({
  selector: 'app-log-api',
  templateUrl: './log-api.component.html',
  styleUrls: ['./log-api.component.scss'],
})
export class LogApiComponent implements OnInit {
  empresa: any = [];
  init_dash = false;

  filtro_request: FiltroRequest = {
    usuario_id: localStorage.getItem('id'),
  };
  constructor(
    private api: ApiService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }
  ionViewWillEnter() {
    this.getLogApi();
  }
  getLogApi(event?) {
    this.init_dash = false;
    this.cdr.detectChanges();
    this.filtro_request.usuario_id = localStorage.getItem('id');
    this.api.post('api/log', this.filtro_request).then(
      async (response: any) => {
        if (response) {
          this.empresa = [];
          this.empresa = response;
        }
      }
    ).finally(() => {
      setTimeout(() => {
        this.init_dash = true;
        this.cdr.detectChanges();
        event?.target.complete();

      }, 200);
    }).catch((err: HttpErrorResponse) => {
      // simple logging, but you can do a lot more, see below
      console.error('Ocorreu um Erro getLogApi ', err.error);
    });
  }
}
