import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/service/api.service';
import { SuccessDialogComponent } from 'src/app/components/success-dialog/success-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { fuseAnimations } from 'src/app/components/animations';
import { FuseAlertType } from 'src/app/components/alert';
import { AddEmpresaDialogComponent } from 'src/app/components/add-empresa-dialog/add-empresa-dialog.component';
import { AddUsuarioDialogComponent } from 'src/app/components/add-usuario-dialog/add-usuario-dialog.component';

interface FiltroRequestUsuario {
  iddesenvolvedor: string;
}
interface FiltroRequestEmpresa {
  idusuario: string;
}
@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class UsuarioComponent implements OnInit {
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };
  showAlert: boolean = false;

  usuarios: any = [];
  empresa: any = [];
  date = new Date();
  init_dash = false;
  adm = false;
  filtro_request_usuario: FiltroRequestUsuario = {
    iddesenvolvedor: localStorage.getItem('idDesenvolvedor'),
  };
  filtro_request_empresa: FiltroRequestEmpresa = {
    idusuario: '',
  };
  filtro = {
    dataInicio: this.date,
    dataFim: this.date,
    mesAtual: new Date().getMonth(),
    semanaAtual: 0,
    dia: 0,
    selecionado: 'DIA'
  }
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private overlay: Overlay,
    private router: Router,
  ) {
  }

  ngOnInit() {
  }
  ionViewWillEnter() {
    if (localStorage.getItem('desenvolvedor') == 'true') {
      this.adm = true;
    } else {
      this.adm = false;
    }
    this.getUsuarios();
  }

  getUsuarios() {
    this.init_dash = false;
    this.filtro_request_usuario.iddesenvolvedor= localStorage.getItem('idDesenvolvedor');
    this.api.post('pesquisarTabUsuario', this.filtro_request_usuario).then(
      async (response: any) => {
        if (response) {
          this.usuarios = [];
          this.usuarios = response.usuarios;
        }
      }
    ).finally(() => {
      setTimeout(() => {
        this.init_dash = true;
        this.cdr.detectChanges();
      }, 200);
    });
  }
  getUsuarioEmpresa(item) {
    this.filtro_request_empresa.idusuario = item;
    this.api.post('pesquisarUsuarioEmpresa', this.filtro_request_empresa).then(
      async (response: any) => {
        if (response) {
          this.empresa = [];
          for (let a = 0; a < response.empresas.length; a++) {
            let element: any = response.empresas[a];
            this.empresa.push(JSON.parse(element));
          }
          let html = this.empresa.map(e => `<li class="text-xl border-b border-gray w-full my-3 capitalize	">${e.descricao}</li>`).join('')
          let usuario_nome = this.usuarios.find(e => e.idusuario == item.toString());
          this.openDialogVerUsuario(usuario_nome, item, html);
        }
      }
    ).finally(() => {
      this.cdr.detectChanges();
    });
  }

  excluirEmpresa(item) {
    this.showAlert = false;
    let usuario: string;
    usuario = item;
    this.api.post('excluirUsuario', { idusuario: usuario }).then(
      async (response: any) => {
        if (response.retorno == "true") {
          this.showAlert = true;
          this.alert = {
            type: 'success',
            message: 'Usuário excluído com sucesso'
          };
          this.getUsuarios();
        } else {
          this.showAlert = true;
          this.alert = {
            type: 'error',
            message: 'Houve um erro ao tentar excluir o usuario'
          };
        }
      }
    ).finally(() => {
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
      this.cdr.detectChanges();
    });
  }

  openDialogVerUsuario(usuario, id, empresas): void {
    if (empresas != '' && empresas != null) {
      empresas = empresas;
    } else {
      empresas = `<li class="text-xl border-b border-gray w-full my-3 uppercase text-red-500">Nenhuma Empresa Vinculada</li>`
    }
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const { ...data } = {
      icon: 'help_outline',
      icon_color: '#cc4b5c',
      title: 'Detalhes do Usuário',
      sub_title: `<b class="text-primary text-2xl">Usuário</b><br>
        ${usuario.usuario}`,
      message: `<b class="text-primary text-2xl">Id do Usuário</b><br>
      <p class="text-xl">${id}</p>
      <b class="text-primary text-2xl">Empresas Vinculados</b><br>
        <ul class="max-h-48 overflow-auto	"> ${empresas}
          </ul>
       `,
      actions: {
        confirm: {
          label: 'Ok',
          color: '#cc4b5c',
        },
        // "cancel": {
        // "label": "Cancel"
        // }
      },
      dismissible: true,
    };
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '500px',
      data: data,
      panelClass: 'succes-modal',
      scrollStrategy,
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  openDialogExcluirUsuario(id): void {

    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const { ...data } = {
      icon: 'help_outline',
      icon_color: '#a70808',
      title: '<span class="text-red-800">Excluir Usuário</span>',
      sub_title: 'Tem certeza que deseja excluir esse usuário?',
      message: '',
      actions: {
        confirm: {
          label: 'Excluir',
          color: '#a70808',
        },
        "cancel": {
          "label": "Cancelar"
        }
      },
      dismissible: true,
    };
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '500px',
      data: data,
      panelClass: 'succes-modal',
      scrollStrategy,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.excluirEmpresa(id);
      } else {

      }
    });
  }

  async openDialogAddUsuario(item?) {
    this.showAlert = false;

    if (item) {
      this.filtro_request_empresa.idusuario = item?.idusuario;
      await this.api.post('pesquisarUsuarioEmpresa', this.filtro_request_empresa).then(
        async (response: any) => {
          if (response) {
            this.empresa = [];
            for (let a = 0; a < response.empresas.length; a++) {
              let element: any = response.empresas[a];
              await this.empresa.push(JSON.parse(element));
            }
          }
        }
      ).finally(() => {
        this.cdr.detectChanges();
      });
    }

    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const { ...data } = {
      usuario: item?.usuario,
      empresas: this.empresa.map(e => Number(e.idempresa)),
      empresas_vinculadas: this.empresa.map(e => e.descricao),
      usuario_id: item?.idusuario,
      senha: item?.senha,
      email: item?.email
    }
    const dialogRef = this.dialog.open(AddUsuarioDialogComponent, {
      width: '500px',
      data: data,
      panelClass: 'succes-modal',
      scrollStrategy,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.value == true) {
          if (result.action == 'adicionar') {
            this.alert = {
              type: 'success',
              message: 'Usuário adicionado com sucesso'
            };
          } else if (result.action == 'editar') {
            this.alert = {
              type: 'success',
              message: 'Usuário editado com sucesso'
            };
          }
          this.showAlert = true;
        } else {
          this.alert = {
            type: 'error',
            message: `Houve um erro ao tentar ${result.action} o usuário. ${result.message}`
          };
          this.showAlert = true;
        }
        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
        this.getUsuarios();
      } else {

      }
    });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["login"]);
  }
}
