import { NavigationEnd, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnDestroy {
  version: string = require( '../../../package.json').version;
  mostrar = true;
  sub;
  adm = false;
  constructor(
    private router: Router,
    private crd: ChangeDetectorRef
  ) {
    if (localStorage.getItem('desenvolvedor') == 'true') {
      this.adm = true;
    } else {
      this.adm = false;
    }
  }
  ngOnDestroy() {

  }
  ionViewWillEnter() {
  }
  logout() {
    localStorage.clear();
    this.router.navigate(["login"]);
  }
  ionViewWillLeave() {
    if (this.sub) this.sub.unsubscribe();
    // if (this.subscription2) this.subscription2.unsubscribe();
    // if (this.subscription3) this.subscription3.unsubscribe();
    // if (this.subscription4) this.subscription4.unsubscribe();
  }
}
