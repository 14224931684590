import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/service/api.service';
import { SuccessDialogComponent } from 'src/app/components/success-dialog/success-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { fuseAnimations } from 'src/app/components/animations';
import { FuseAlertType } from 'src/app/components/alert';
import { AddEmpresaDialogComponent } from 'src/app/components/add-empresa-dialog/add-empresa-dialog.component';

interface FiltroRequestEmpresa {
  iddesenvolvedor: string;
}
interface FiltroRequestUsuario {
  idempresa: string;
}
@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class EmpresaComponent implements OnInit {
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };
  showAlert: boolean = false;

  empresas: any = [];
  usuario: any = [];
  date = new Date();
  init_dash = false;
  adm = false;
  filtro_request_empresa: FiltroRequestEmpresa = {
    iddesenvolvedor: localStorage.getItem('idDesenvolvedor'),
  };
  filtro_request_usuario: FiltroRequestUsuario = {
    idempresa: '',
  };
  filtro = {
    dataInicio: this.date,
    dataFim: this.date,
    mesAtual: new Date().getMonth(),
    semanaAtual: 0,
    dia: 0,
    selecionado: 'DIA'
  }
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private overlay: Overlay,
    private router: Router
  ) {
  }

  ngOnInit() {
    // if (localStorage.getItem('desenvolvedor') == 'true') {
    //   this.adm = true;
    // } else {
    //   this.adm = false;
    // }
    // this.getEmpresas();
  }
  ionViewWillEnter() {
    if (localStorage.getItem('desenvolvedor') == 'true') {
      this.adm = true;
    } else {
      this.adm = false;
    }
    this.getEmpresas();
  }

  getEmpresas() {
    this.init_dash = false;
    this.api.post('pesquisarEmpresa', this.filtro_request_empresa).then(
      async (response: any) => {
        if (response) {
          this.empresas = [];
          this.empresas = response.empresas;
        }
      }
    ).finally(() => {
      setTimeout(() => {
        this.init_dash = true;
        this.cdr.detectChanges();
      }, 200);
    });
  }
  getUsuario(item) {
    this.filtro_request_usuario.idempresa = item;
    this.api.post('pesquisarUsuario', this.filtro_request_usuario).then(
      async (response: any) => {
        if (response) {
          this.usuario = [];
          for (let a = 0; a < response.usuario.length; a++) {
            let element: any = response.usuario[a];
            this.usuario.push(JSON.parse(element));
          }
          let html = this.usuario.map(e => `<li class="text-xl border-b border-gray w-full my-3 capitalize	">${e.usuario}</li>`).join('')
          let empresa_nome = this.empresas.find(e => e.idempresa == item.toString());

          this.openDialogVerEmpresa(empresa_nome, item, html);
        }
      }
    ).finally(() => {
      this.cdr.detectChanges();
    });
  }

  excluirEmpresa(item) {
    this.showAlert = false;
    let empresa: string;
    empresa = item;
    this.api.post('excluirEmpresa', { idempresa: empresa }).then(
      async (response: any) => {
        if (response.retorno == "true") {
          this.showAlert = true;
          this.alert = {
            type: 'success',
            message: 'Empresa excluída com sucesso'
          };
          this.getEmpresas();
        } else {
          this.showAlert = true;
          this.alert = {
            type: 'error',
            message: 'Houve um erro ao tentar excluir a empresa'
          };
        }
      }
    ).finally(() => {
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
      this.cdr.detectChanges();
    });
  }



  openDialogVerEmpresa(empresa, id, usuarios): void {
    if (usuarios != '' && usuarios != null) {
      usuarios = usuarios;
    } else {
      usuarios = `<li class="text-xl border-b border-gray w-full my-3 uppercase text-red-500">Nenhum Usuário Vinculado</li>`
    }
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const { ...data } = {
      icon: 'help_outline',
      icon_color: '#cc4b5c',
      title: 'Detalhes da empresa',
      sub_title: `<b class="text-primary text-2xl">Empresa</b><br>
        ${empresa.descricao}`,
      message: `<b class="text-primary text-2xl">Id da Empresa</b><br>
      <p class="text-xl">${id}</p>
      <b class="text-primary text-2xl">Usuarios Vinculados</b><br>
      <ul class="max-h-48 overflow-auto	"> ${usuarios}
          </ul>
       `,
      actions: {
        confirm: {
          label: 'Ok',
          color: '#cc4b5c',
        },
        // "cancel": {
        // "label": "Cancel"
        // }
      },
      dismissible: true,
    };
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '500px',
      data: data,
      panelClass: 'succes-modal',
      scrollStrategy,
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }
  openDialogExcluirEmpresa(id): void {

    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const { ...data } = {
      icon: 'help_outline',
      icon_color: '#a70808',
      title: '<span class="text-red-800">Exluir Empresa</span>',
      sub_title: 'Tem certeza que deseja excluir essa empresa?',
      message: '',
      actions: {
        confirm: {
          label: 'Excluir',
          color: '#a70808',
        },
        "cancel": {
          "label": "Cancelar"
        }
      },
      dismissible: true,
    };
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '500px',
      data: data,
      panelClass: 'succes-modal',
      scrollStrategy,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.excluirEmpresa(id);
      } else {

      }
    });
  }
  openDialogAddEmpresa(item?): void {
    this.showAlert = false;
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const { ...data } = {
      empresa_nome: item?.descricao,
      empresa_ativa: item?.ativo,
      empresa_id: item?.idempresa
    }
    const dialogRef = this.dialog.open(AddEmpresaDialogComponent, {
      width: '500px',
      data: data,
      panelClass: 'succes-modal',
      scrollStrategy,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.value == true) {
          if (result.action == 'adicionar') {
            this.alert = {
              type: 'success',
              message: 'Empresa adicionada com sucesso'
            };
          } else if (result.action == 'editar') {
            this.alert = {
              type: 'success',
              message: 'Empresa editada com sucesso'
            };
          }
          this.showAlert = true;
        } else {
          this.alert = {
            type: 'error',
            message: `Houve um erro ao tentar ${result.action} a empresa`
          };
          this.showAlert = true;
        }
        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
        this.getEmpresas();
      } else {

      }
    });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["login"]);
  }
}
