import { ApiService } from '../../service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { passwordConfirme } from 'src/app/validators/passwordConfirme.validator';
export interface DialogData {
  descricao
}
interface FiltroRequestEmpresa {
  iddesenvolvedor: string;
}
@Component({
  templateUrl: './add-usuario-dialog.component.html',
  styleUrls: ['./add-usuario-dialog.component.scss']
})

export class AddUsuarioDialogComponent implements OnInit {
  addUsuarioForm: FormGroup;
  _data;
  sub;
  ambiente;
  editar = false;
  result = { value: false, action: '', message: '' };
  empresas = [];
  empresas_vinculadas = [];
  filtro_request_empresa: FiltroRequestEmpresa = {
    iddesenvolvedor: localStorage.getItem('idDesenvolvedor'),
  };
  editar_empresas = false;
  old_senha = '';
  constructor(
    public dialogRef: MatDialogRef<AddUsuarioDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _formBuilder: FormBuilder,
    private api: ApiService,
    private cdr: ChangeDetectorRef,
  ) {
    this._data = data;
    this.addUsuarioForm = this._formBuilder.group({
      usuario: ['', Validators.required],
      senha: ['', Validators.required],
      idusuario: [''],
      ignorar_senha: [false],
      email: ['',Validators.compose([Validators.required,Validators.email])],
      empresas: [[]], 
      confirme_senha: ['', Validators.required],
    },{ validator: passwordConfirme });
  }

  async ngOnInit() {
    await this.formatForm();
    this.addUsuarioForm.valueChanges.subscribe(e =>{console.log(e),console.log(this.addUsuarioForm)});
    this.addUsuarioForm.controls.ignorar_senha.valueChanges.subscribe( e => {
      console.log(e)
      if (e) {
        this.addUsuarioForm.controls.senha.setValue('');
        this.addUsuarioForm.controls.senha.clearValidators();
        this.addUsuarioForm.controls.senha.updateValueAndValidity();
        this.addUsuarioForm.controls.confirme_senha.setValue('');
        this.addUsuarioForm.controls.confirme_senha.clearValidators();
        this.addUsuarioForm.controls.confirme_senha.updateValueAndValidity();
        this.addUsuarioForm.clearValidators();
        this.addUsuarioForm.updateValueAndValidity();
      } else {
        this.addUsuarioForm.controls.senha.setValidators([ Validators.required ]);
        this.addUsuarioForm.controls.senha.updateValueAndValidity();
        this.addUsuarioForm.controls.confirme_senha.setValidators([ Validators.required]);
        this.addUsuarioForm.controls.confirme_senha.updateValueAndValidity();
        this.addUsuarioForm.setValidators([ passwordConfirme]);
        this.addUsuarioForm.updateValueAndValidity();
      }
    });
    this.getEmpresas();
  }
  async ionViewWillEnter() {
    await this.formatForm()
    this.getEmpresas();
  }
  async formatForm() {
    if (this._data.usuario) {
      this.editar = true;

      this.empresas_vinculadas = this._data.empresas_vinculadas;
      await this.addUsuarioForm.controls.usuario.setValue(this._data.usuario);
      await this.addUsuarioForm.controls.idusuario.setValue(this._data.usuario_id);
      await this.addUsuarioForm.controls.empresas.setValue(this._data.empresas);
      await this.addUsuarioForm.controls.email.setValue(this._data.email);
      this.old_senha = this._data.senha;
    } else {
      this.editar = false;
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }

  async salvar() {
    await this.addEmpresa();
    await this.dialogRef.close(this.result);
  }

  async addEmpresa() {
    const controls = this.addUsuarioForm.controls;
    if (this.addUsuarioForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.addUsuarioForm.disable();
    let usuario: any = {
      usuario: '',
      senha: '',
      idusuario: '',
      empresas: [],
    };
    Object.keys(controls).forEach(controlName =>
      usuario[controlName] = controls[controlName].value
    );

    if (usuario.empresas) {
      usuario.empresas = usuario?.empresas?.join();
    }
    if (usuario.idusuario) {
      usuario.iddesenvolvedor = localStorage.getItem('id');
      if (!usuario.senha) {
        // usuario.senha = this.old_senha;
        delete usuario.senha
      }
      await this.api.post('editarUsuario', usuario).then(
        async (response: any) => {
          if (response.retorno == "true") {
            this.result.value = true;
            this.result.action = 'editar';
          } else {
            this.result.value = false;
            this.result.action = 'editar';
          }
        }
      )
        .catch((erro) => {
          this.result.value = false,
            this.result.action = 'editar'
            this.result.message = erro?.error?.message || '';
        })
        .finally(() => {
          this.cdr.detectChanges();
        });
    } else {
      delete usuario.idusuario;
      usuario.iddesenvolvedor = localStorage.getItem('id');
      await this.api.post('addUsuario', usuario).then(
        async (response: any) => {
          if (response.retorno == "true") {
            this.result.value = true;
            this.result.action = 'adicionar';
          } else {
            this.result.value = false;
            this.result.action = 'adicionar';
          }
        }
      ).catch((erro) => {
        this.result.value = false,
          this.result.action = 'adicionar',
          this.result.message = erro?.error?.message || '';
      })
        .finally(() => {
          this.cdr.detectChanges();
        });
    }
  }

  getEmpresas() {
    this.api.post('pesquisarEmpresa', this.filtro_request_empresa).then(
      async (response: any) => {
        if (response) {
          this.empresas = [];
          this.empresas = response.empresas;
        }
      }
    ).finally(() => {
      setTimeout(() => {
        this.cdr.detectChanges();
      }, 200);
    });
  }

}
