import { ChangeDetectorRef, Component, OnInit, Inject } from '@angular/core';
import { ApexOptions } from 'ng-apexcharts';
import { ApiService } from '../service/api.service';
import * as moment from 'moment';
import { DatePipe, DOCUMENT, formatDate } from '@angular/common';
import { style } from '@angular/animations';
import { colorSets } from '@swimlane/ngx-charts';
import { parse } from 'path';
import { values } from 'lodash';
interface Empresa {
  empresa: string;
  Dias: [];
  Horas: [];
  Semanas: [];
  Meses: [];
  valorTotalDia: [];
  valorTotalHora: [];
  valorTotalSemana: [];
  valorTotalMes: [];
}

interface FiltroRequest {
  usuario_id: string;
  dataInicio: string;
  dataFim: string;
}
@Component({
  selector: 'app-evolucao-vendas',
  templateUrl: './evolucao-vendas.component.html',
  styleUrls: ['./evolucao-vendas.component.scss'],
})
export class EvolucaoVendasComponent implements OnInit {
    apexChartOptions: ApexOptions = {
        chart: {
          height: 550,
          width: '95%',
          type: 'bar',
          animations: {
            speed: 400,
          },
          stacked: false,
          toolbar: {
          show: true
          },
        },
        labels: [],
        series: [],
        xaxis: {
          categories: []
        },
        title: {
          text: undefined,
          align: 'left',
          margin: 0,
          offsetX: 50,
          offsetY: 0,
          style: {
            fontSize: '25px',
            fontWeight: 'bold',
            fontFamily: 'inherit',
            color: '#303030'
          },
        },
        subtitle: {
          text: undefined,
          align: 'left',
          margin: 20,
          offsetX: 50,
          offsetY: 30,
          style: {
            fontSize: '18px',
            fontWeight: 'normal',
            fontFamily: 'inherit',
            color: '#303030'
          },
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          textAnchor: 'middle',
          style: {
            fontSize: '14px',
            fontFamily: 'Roboto, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#ffffff']
          }
        },
        responsive: [
          {
            breakpoint: 1200,
            options: {
              plotOptions: {
                bar: {
                  offsetX: 0,
                }
              },
              legend: {
                offsetX: 0,
              }
            }
          },
          {
            breakpoint: 1000,
            options: {
              chart: {
                height: 300,
                width: '95%',
              },
            }
          },
          {
            breakpoint: 800,
            options: {
              plotOptions: {
                bar: {
                  customScale: 1,
                  offsetX: -20,
                  offsetY: 0,
                }
              },
              subtitle: {
                margin: 0,
                offsetX: 0,
                offsetY: 100,
              },
              title: {
                fontsize: '5px',
                margin: 20,
                offsetX: 0,
                offsetY: 0,
              },
              chart: {
                height: 300,
                width: '95%',
              },
              dataLabels: {
                style: {
                  fontSize: '8px',
                }
              },
              xaxis:{
                labels: {
                  show: false,
                },
                style:{
                  fontSize: '10px',
                },
                title: {
                  offsetY: -20,
                }
              },
            }
          },
        ],
      };

      apexChartOptionsGeral: ApexOptions = {
        responsive: [
          {
            breakpoint: 1200,
            options: {
              plotOptions: {
                bar: {
                  offsetX: 0,
                }
              },
              legend: {
                offsetX: 0,
              }
            }
          },
          {
            breakpoint: 1000,
            options: {
              chart: {
                height: 300,
                width: '95%',
              },
            }
          },
          {
            breakpoint: 800,
            options: {
              plotOptions: {
                bar: {
                  customScale: 1,
                  offsetX: -20,
                  offsetY: 0,
                }
              },
              subtitle: {
                margin: 0,
                offsetX: 0,
                offsetY: 50,
              },
              title: {
                fontsize: '5px',
                margin: 20,
                offsetX: 0,
                offsetY: 0,
              },
              chart: {
                height: 300,
                width: '95%',
              },
              dataLabels: {
                style: {
                  fontSize: '8px',
                }
              },
              xaxis:{
                labels: {
                  show: false,
                },
                style:{
                  fontSize: '10px',
                },
                title: {
                  offsetY: -20,
                }
              }
            }
          },
        ],
      };
      empresa: Empresa[] = [];
      date = new Date();
      init_dash = false;
      chart_width;
      filtro_request: FiltroRequest = {
        usuario_id: localStorage.getItem('id'),
        dataInicio: this.date.toISOString().substring(0, 10),
        dataFim: this.date.toISOString().substring(0, 10),
      };
      filtro = {
        dataInicio: this.date,
        dataFim: this.date,
        mesAtual: new Date().getMonth(),
        semanaAtual: 0,
        dia: 0,
        selecionado: 'DIA'
      }

     constructor(
        private api: ApiService,
        private datePipe: DatePipe,
        private cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: HTMLDocument,
      ) {
      }
      mesAtual = new Date().getMonth();

      SeriesHora = {... this.apexChartOptions.series};
      XaxisHora = {... this.apexChartOptions.xaxis};
      YaxisHora = {... this.apexChartOptions.yaxis};
      TitleHora = {... this.apexChartOptions.title};
      SubTitleHora = {... this.apexChartOptions.subtitle};

      SeriesDia = {... this.apexChartOptions.series};
      XaxisDia = {... this.apexChartOptions.xaxis};
      YaxisDia = {... this.apexChartOptions.yaxis};
      TitleDia = {... this.apexChartOptions.title};
      SubTitleDia = {... this.apexChartOptions.subtitle};

      SeriesSemana = {... this.apexChartOptions.series};
      XaxisSemana = {... this.apexChartOptions.xaxis};
      YaxisSemana = {... this.apexChartOptions.yaxis};
      TitleSemana = {... this.apexChartOptions.title};
      SubTitleSemana = {... this.apexChartOptions.subtitle};

      SeriesMes = {... this.apexChartOptions.series};
      XaxisMes = {... this.apexChartOptions.xaxis};
      YaxisMes = {... this.apexChartOptions.yaxis};
      TitleMes = {... this.apexChartOptions.title};
      SubTitleMes = {... this.apexChartOptions.subtitle};

      ngOnInit() {
      }
      ionViewWillEnter() {
        this.getEvolucaoVendas();
      }
      getEvolucaoVendas(event?) {
        const mesesNomes = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        this.init_dash = false;
        this.cdr.detectChanges();
        this.filtro_request.usuario_id = localStorage.getItem('id');
        this.filtro_request.dataFim = this.datePipe.transform(this.filtro.dataFim, "yyyy-MM-dd");
        this.filtro_request.dataInicio = this.datePipe.transform(this.filtro.dataInicio, "yyyy-MM-dd");
        this.api.post('api/evolucao-vendas', this.filtro_request).then(
          async (response: any) => {
            if (response) {
              this.empresa = [];
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let a = 0; a < response.empresas.length; a++) {
                let element = response.empresas[a];
                // ===== VALOR POR HORA =====
                element.SeriesHora = [{
                  name: 'Valor por Hora',
                  data: element.valorTotalHora // sempre somar o data + element.valorTotalHora
                  }
                ];

                element.XaxisHora = {
                  categories: element.Horas.map((hora) => `${hora}:00`),
                  labels: {
                    formatter: function(value) {
                      return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    }
                  },
                  title: {
                    text: 'Valor'
                  }
                };

                element.YaxisHora = {
                  labels: {
                    formatter: function(value) {
                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    }
                  },
                  title: {
                    text: 'Horas'
                  }
                };


                let titleHora = {... this.apexChartOptions.title};
                titleHora.text = 'Evolução Por Hora';
                element.TitleHora = titleHora;
                const sumValorTotalHora = element.valorTotalHora
                .map(valor => parseFloat(valor))
                .reduce((acc, valor) => acc + valor, 0);

                let subtitleHora = { ... this.apexChartOptions.subtitle };
                subtitleHora.text = 'Valor Total = ' + Number(sumValorTotalHora).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });

                element.SubTitleHora = subtitleHora;

                // ====== VALOR POR DIA ======
                element.SeriesDia = [{
                  name: 'Valor por Dia',
                  data: element.valorTotalDia,
                  }
                ];
  
                element.XaxisDia = {
                  categories: element.Dias.map((dia) => this.getDiadaSemanaReduzido(dia)),
                  labels: {
                    formatter: function(value) {
                      return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    }
                  },
                  title: {
                    text: 'Valor'
                  },
                };
  
                element.YaxisDia = {
                  labels: {
                    formatter: function(value) {
                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    }
                  },
                  title: {
                    text: 'Dias'
                  }
                };
                
                let titleDia = {... this.apexChartOptions.title};
                titleDia.text = 'Evolução Por Dia';
                element.TitleDia = titleDia;

                const sumValorTotalDia = element.valorTotalDia
                .map(valor => parseFloat(valor))
                .reduce((acc, valor) => acc + valor, 0);

                let subTitleDia = {... this.apexChartOptions.subtitle};
                subTitleDia.text = 'Valor Total = ' + Number(sumValorTotalDia).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
                element.SubTitleDia = subTitleDia;

                // ===== VALOR POR SEMANA =====
                element.SeriesSemana = [{
                  name: 'Valor por Semana',
                  data: element.valorTotalSemana,
                  }
                ];
  
                element.XaxisSemana = {
                  categories: element.Semanas.map((semana) => this.getSemanadoMesReduzido(semana)),
                  labels: {
                    formatter: function(value) {
                      return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    }
                  },
                  title: {
                    text: 'Valor'
                  },
                };
  
                element.YaxisSemana = {
                  labels: {
                    formatter: function(value) {
                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    }
                  },
                  title: {
                    text: 'Semanas'
                  }
                };
  
                let titleSemana = {... this.apexChartOptions.title};
                titleSemana.text = 'Evolução Por Semana';
                element.TitleSemana = titleSemana;
                const sumValorTotalSemana = element.valorTotalSemana
                .map(valor => parseFloat(valor))
                .reduce((acc, valor) => acc + valor, 0);

                let subTitleSemana = {... this.apexChartOptions.subtitle};
                subTitleSemana.text = 'Valor Total = ' + Number(sumValorTotalSemana).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
                element.SubTitleSemana = subTitleSemana;

                

                // ===== VALOR POR MES =====
                element.SeriesMes = [{
                  name: 'Valor por Mês',
                  data: element.valorTotalMes,
                  }
                ];

                element.XaxisMes = {
                  categories: element.Meses.map(month => mesesNomes[month - 1]),
                  labels: {
                    formatter: function(value) {
                      return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    }
                  },
                  title: {
                    text: 'Meses'
                  },
                };

                element.YaxisMes = {
                  labels: {
                    formatter: function(value) {
                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    }
                  },
                  title: {
                    text: 'Meses'
                  }
                };

                let titleMes = {... this.apexChartOptions.title};
                titleMes.text = 'Evolução Por Mês';
                element.TitleMes = titleMes;
                const sumValorTotalMes = element.valorTotalMes
                .map(valor => parseFloat(valor))
                .reduce((acc, valor) => acc + valor, 0);
                
                let subTitleMes = {... this.apexChartOptions.subtitle};
                subTitleMes.text = 'Valor Total = ' + Number(sumValorTotalMes).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }); 
                element.SubTitleMes = subTitleMes;
                  this.empresa.push(element);
              };
    // ============================== FIM DO FOR ======================================================
    // ============================== CRIANDO APEX CHART BAR HORA ======================================================
              this.SeriesHora = [{
                name: 'Valor Hora',
                data: response.totalEmpresaHora.map(e => Number(e.valor)),
              }];

              this.XaxisHora = {
                categories: response.totalEmpresaHora.map(e => `${e.hora}:00`),
                labels: {
                  formatter: function(value) {
                    return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                  }
                },
                title: {
                  text: 'Horas'
                },
              };

              this.YaxisHora = [{
                labels: {
                  formatter: function(value) {
                    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                  }
                },
                title: {
                  text: 'Horas'
                }
              }];

              this.TitleHora = {
                text: 'Evolução por Hora',
                style: {
                  fontSize: '20px'
                }
              };

              const ValorTotalHora = response.totalEmpresaHora.map(e => Number(e.valor)).reduce((partialSum, a) => partialSum + a, 0);
              this.SubTitleHora = {
                  text: 'Valor Total = ' + Number(ValorTotalHora).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }),
                  style: {
                    fontSize: '14px'
                  }
              };
    // =====================  CRIANDO APEX CHAR BAR DIA ==============================================
              this.SeriesDia = [{
                name: 'Valor por Dia',
                data: response.totalEmpresaDia.map(e => Number(e.valor)),
              }];

              this.XaxisDia = {
                categories: response.totalEmpresaDia.map((e) => this.getDiadaSemanaReduzido(e.dia)),
                labels: {
                  formatter: function(value) {
                    return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                  }
                },
                title: {
                  text: 'Dias'
                },
              };

              this.YaxisDia = [{
                labels: {
                  formatter: function(value) {
                    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                  }
                },
                title: {
                  text: 'Dias'
                }
              }];

              this.TitleDia = {
                text: 'Evolução por Dia',
                style: {
                  fontSize: '20px'
                }
              };

              const ValorTotalDia = response.totalEmpresaDia.map(e => Number(e.valor)).reduce((partialSum, a) => partialSum + a, 0);
              this.SubTitleDia = {
                  text: 'Valor Total = ' + Number(ValorTotalDia).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }),
                  style: {
                    fontSize: '14px'
                  }
              };

    // ============================= CRIANDO APEX BAR SEMANA ==========================================
        this.SeriesSemana = [{
          name: 'Valor por Semana',
          data: response.totalEmpresaSemana.map(e => Number(e.valor)),
        }];

        this.XaxisSemana = {
          categories: response.totalEmpresaSemana.map((e) => this.getSemanadoMesReduzido(e.semana)),
          labels: {
            formatter: function(value) {
              return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            }
          },
          title: {
            text: 'Semanas'
          },
        };

        this.YaxisSemana = [{
          labels: {
            formatter: function(value) {
              return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            }
          },
          title: {
            text: 'Semanas'
          }
        }];

        this.TitleSemana = {
          text: 'Evolução por Semana',
          style: {
            fontSize: '20px'
          }
        };

        const ValorTotalSemana = response.totalEmpresaSemana.map(e => Number(e.valor)).reduce((partialSum, a) => partialSum + a, 0);
        this.SubTitleSemana = {
            text: 'Valor Total = ' + Number(ValorTotalSemana).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }),
            style: {
              fontSize: '14px'
            }
        };

    // =================== CRIANDO APEX CHART BAR MES ===============================================================

      this.SeriesMes = [{
        name: 'Valor por Mês',
        data: response.totalEmpresaMes.map(e => Number(e.valor)),
      }];

      this.XaxisMes = {
        categories: response.totalEmpresaMes.map(e => mesesNomes[e.mes - 1]),
        labels: {
          formatter: function(value) {
            return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          }
        },
        title: {
          text: 'Mês'
        },
      };

      this.YaxisMes = [{
        labels: {
          formatter: function(value) {
            return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          }
        },
        title: {
          text: 'Meses'
        }
      }];

      this.TitleMes = {
        text: 'Evolução por Meses',
        style: {
          fontSize: '20px'
        }
      };

      const ValorTotalMes = response.totalEmpresaMes.map(e => Number(e.valor)).reduce((partialSum, a) => partialSum + a, 0);
      this.SubTitleMes = {
          text: 'Valor Total = ' + Number(ValorTotalMes).toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }),
          style: {
            fontSize: '14px'
          }
      };

            }
          }
        ).finally(() => {
          setTimeout(() => {
            this.init_dash = true;
            this.cdr.detectChanges();
            event?.target.complete();

          }, 200);
        });
      }




      getDiadaSemanaReduzido(dia: number){
        const diasDaSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'];
        let diaSemana = `${diasDaSemana[dia -1]}`;
        return diaSemana;
      }


      getSemanadoMesReduzido(semana: number){
        const semanaDoMes = ['1', '2', '3', '4', '5'];
        let Semanas = `${semanaDoMes[semana -1]} semana`;
        return Semanas;
      }

      proximo() {
        switch (this.filtro.selecionado) {
          case 'SEMANA':
            this.filtro.semanaAtual++;
            this.addSemana();
            break;
          case 'MES':
            this.filtro.mesAtual++;
            this.addMes();
            break;
          case 'DIA':
            this.filtro.dia++;
            this.addDia();
            break;
        }
      }

      anterior() {
        switch (this.filtro.selecionado) {
          case 'SEMANA':
            this.filtro.semanaAtual--;
            this.addSemana();
            break;
          case 'MES':
            this.filtro.mesAtual--;
            this.addMes();
            break;
          case 'DIA':
            this.filtro.dia--;
            this.addDia();
            break;
        }
      }

      addSemana() {
        this.filtro.selecionado = 'SEMANA';
        const inicio = moment().add(this.filtro.semanaAtual, 'weeks').day(0); // domingo desta semana
        const fim = moment().add(this.filtro.semanaAtual, 'weeks').day(6); // sábado desta semana
        this.filtro.dataInicio = new Date(inicio.toString());
        this.filtro.dataFim = new Date(fim.toString());
      }
      addMes() {
        this.filtro.selecionado = 'MES';
        const d = new Date();
        const anoC = d.getFullYear();
        const mesC = this.filtro.mesAtual;
        this.filtro.dataInicio = new Date(anoC, mesC, 1);
        this.filtro.dataFim = new Date(anoC, mesC + 1, 0);
      }
      addDia() {
        this.filtro.selecionado = 'DIA';
        const date = new Date();
        const emissaoDe = new Date();
        const emissaoAte = new Date();
        this.filtro.dataInicio = new Date(emissaoDe.setDate(date.getDate() + this.filtro.dia));
        this.filtro.dataFim = new Date(emissaoAte.setDate(date.getDate() + this.filtro.dia));
      }
    }