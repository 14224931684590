import { AuthGuardAdm } from './service/auth-guard-adm.service';
import { AuthGuardCliente } from './service/auth-guard-cliente.service';
import { AuthGuard } from './service/auth-guard.service';
import { TabsComponent } from './tabs/tabs.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NoAuthGuard } from './service/no-auth-guard.service';
import { GeralComponent } from './geral/geral.component';
import { FormaPagamentoComponent } from './forma-pagamento/forma-pagamento.component';
import { PrazoPagamentoComponent } from './prazo-pagamento/prazo-pagamento.component';
import { RankingProdutosComponent } from './ranking-produtos/ranking-produtos.component';
import { LogApiComponent } from './log-api/log-api.component';
import { EmpresaComponent } from './adm/empresa/empresa.component';
import { UsuarioComponent } from './adm/usuario/usuario.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { RankingVendedorComponent } from './ranking-vendedor/ranking-vendedor.component';
import { EvolucaoVendasComponent} from './evolucao-vendas/evolucao-vendas.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'lost/password',
    component: LostPasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'forget/password',
    component: ForgetPasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'forget/password/:token',
    component: ForgetPasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: '',
    redirectTo: 'geral',
    pathMatch: 'full'
  },
  {
    path: '*',
    redirectTo: 'geral',
    // pathMatch: 'full'
  },
  {
    path: '',
    component: TabsComponent,
    canActivate: [AuthGuard, AuthGuardCliente],
    children: [
      {
        path: 'geral',
        component: GeralComponent
      },
      {
        path: 'alterar-senha',
        component: AlterarSenhaComponent
      },
      {
        path: 'forma-pagamento',
        component: FormaPagamentoComponent
      },
      {
        path: 'prazo-pagamento',
        component: PrazoPagamentoComponent
      },
      {
        path: 'log-api',
        component: LogApiComponent
      },
      {
        path: 'ranking-produtos',
        component: RankingProdutosComponent
      },
      {
        path: 'ranking-vendedor',
        component: RankingVendedorComponent
      },
      {
        path: 'evolucao-vendas',
        component: EvolucaoVendasComponent
      }
      // {
      //   path: ':id',
      //   loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule)
      // },


    ]
  },
  {
    path: 'adm',
    component: TabsComponent,
    canActivate: [AuthGuard, AuthGuardAdm],
    children: [
      {
        path: 'empresa',
        component: EmpresaComponent
      },
      {
        path: 'usuario',
        component: UsuarioComponent
      }

    ]
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
