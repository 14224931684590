import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../service/api.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
interface Empresa {
  empresa: string,
  valorTotal: string,
  numVendas: string,
  ticketMedio: string,

}

interface FiltroRequest {
  data_inicio: string;
  data_fim: string;
  limit: boolean;
  usuario_id: string;
  tamanho: string;
  cor: string;
  marca: string;
  genero: string;
  grupo: string;
  subgrupo: string;
  filtro: string;
}
@Component({
  selector: 'app-ranking-produtos',
  templateUrl: './ranking-produtos.component.html',
  styleUrls: ['./ranking-produtos.component.scss'],
})
export class RankingProdutosComponent implements OnInit {
  empresa: any = [];
  date = new Date();
  init_dash = false;

  filtro_request: FiltroRequest = {
    usuario_id: localStorage.getItem('id'),
    data_inicio: this.date.toISOString().substring(0, 10),
    data_fim: this.date.toISOString().substring(0, 10),
    limit: true,
    tamanho: "",
    cor: "",
    marca: "",
    genero: "",
    grupo: "",
    subgrupo: "",
    filtro: "PRODUTO",
  };
  filtro = {
    dataInicio: this.date,
    dataFim: this.date,
    mesAtual: new Date().getMonth(),
    semanaAtual: 0,
    dia: 0,
    selecionado: 'DIA'
  }
  filter_options = [
    { valor: 'PRODUTO', descricao: 'Produto' },
    { valor: 'TAMANHO', descricao: 'Tamanho' },
    { valor: 'COR', descricao: 'Cor' },
    { valor: 'MARCA', descricao: 'Marca' },
    { valor: 'GENERO', descricao: 'Gênero' },
    { valor: 'GRUPO', descricao: 'Grupo' },
    { valor: 'SUBGRUPO', descricao: 'SubGrupo' }
  ]
  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }
  ionViewWillEnter() {
    this.getRanking();
  }
  getRanking(event?) {
    this.init_dash = false;
    this.cdr.detectChanges();
    this.filtro_request.usuario_id = localStorage.getItem('id');
    this.filtro_request.data_fim = this.datePipe.transform(this.filtro.dataFim, "yyyy-MM-dd");
    this.filtro_request.data_inicio = this.datePipe.transform(this.filtro.dataInicio, "yyyy-MM-dd");
    this.api.post('produtos', this.filtro_request).then(
      async (response: any) => {
        if (response) {
          this.empresa = [];
          this.empresa = response.retorno;
          for (let a = 0; a < this.empresa.length; a++) {
            const element = this.empresa[a];
            let reduce = element.produtos.map(e => Number(e.quantidade));
            let total = reduce.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            );
            this.empresa[a]['valorTotal'] = total;
          }
        }
      }
    ).finally(() => {
      setTimeout(() => {
        this.init_dash = true;
        this.cdr.detectChanges();
        event?.target.complete();

      }, 200);
    });
  }

  returnPorcentage(qtd, total) {
    const sum = 100 / total;
    return (sum * qtd).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  }
  mesAtual= new Date().getMonth();

  proximo() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual++;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual++;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia++;
        this.addDia();
        break;
    }
  }

  anterior() {
    switch (this.filtro.selecionado) {
      case 'SEMANA':
        this.filtro.semanaAtual--;
        this.addSemana();
        break;
      case 'MES':
        this.filtro.mesAtual--;
        this.addMes();
        break;
      case 'DIA':
        this.filtro.dia--;
        this.addDia();
        break;
    }
  }

  addSemana() {
    this.filtro.selecionado = 'SEMANA';
    let inicio = moment().add(this.filtro.semanaAtual, 'weeks').day(0); // domingo desta semana
    let fim = moment().add(this.filtro.semanaAtual, 'weeks').day(6); // sábado desta semana
    this.filtro.dataInicio = new Date(inicio.toString());
    this.filtro.dataFim = new Date(fim.toString());
  }
  addMes() {
    this.filtro.selecionado = 'MES';
    let d = new Date();
    let anoC = d.getFullYear();
    let mesC = this.filtro.mesAtual;
    this.filtro.dataInicio = new Date(anoC, mesC, 1);
    this.filtro.dataFim = new Date(anoC, mesC + 1, 0);
  }
  addDia() {
    this.filtro.selecionado = 'DIA';
    let date = new Date();
    let emissaoDe = new Date();
    let emissaoAte = new Date();
    this.filtro.dataInicio = new Date(emissaoDe.setDate(date.getDate() + this.filtro.dia));
    this.filtro.dataFim = new Date(emissaoAte.setDate(date.getDate() + this.filtro.dia));
  }
}
